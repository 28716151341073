$c1: #0d0d0d;
$darkGray: #2e2e32;
$charcoal-grey: #373c40;
$charcoal-grey-two: #484f54;
$hospital-green: #90e095;
$peachy-pink: #fd9b7a;
$salmon: #fd8865;
$white: #f6f4f3;
$white-two: #d7d7d7;
$white-three: $white;
$light-yellow-green: #daff87;
$grey: #9ea1a3;
$light-grey: #a9a9a9;
$slate-grey: #5e676d;
$light-teal: #adebed;
$light-teal-two: #90d8e0;
$orange-red: #ff5725;
$tomato: #e64130;
$tomato-two: #e85644;
$dark-peach: #eb6c5b;
$red: #d50000;
$yellow: #f8e983;
$neutral: #90d8e0;
$BG-dark: #0d0d0d;
$BG-medium: #484f54;
$Black: #212121;
$block-shadow: rgba(0, 0, 0, 0.4);
$boundingbox: #ff0085;
$boundingbox2: #f6f4f3;
$positive: #90e095;
$soft-negative: #ffa98c;
$hard-negative: #e85644;
$emptyvid1: #97a0a6;
$emptyvid2: #5e676d;
$transparantblack: rgba(13, 13, 13, 0.6);
$inactive: #cecece;
$lostConnection: #ffa98c;
$color-primary: #5cbbc6;
$pure-black: #000;

// Custom
$charcoal-grey-light: #d7d8d9;
$error-red: $charcoal-grey;

$main-room-background: linear-gradient(24deg, $emptyvid1, $emptyvid2);
$unit: 1rem;

// Layout
$baseline-grid: 8px !default;
$layout-breakpoint-sm: 600px !default;
$layout-breakpoint-md: 940px !default;
$layout-breakpoint-lg: 1200px !default;
$layout-gutter-width: ($baseline-grid * 2) !default;

$baseline-sizing: (
  0: 0,
  '0-5': $baseline-grid / 2,
  1: $baseline-grid,
  2: $baseline-grid * 2,
  3: $baseline-grid * 3,
  4: $baseline-grid * 4,
  5: $baseline-grid * 5,
  6: $baseline-grid * 6,
  7: $baseline-grid * 7,
  8: $baseline-grid * 8,
  9: $baseline-grid * 9,
  10: $baseline-grid * 10,
  11: $baseline-grid * 11,
  12: $baseline-grid * 12,
  13: $baseline-grid * 13,
  14: $baseline-grid * 14,
  auto: auto,
);

// General purpose
$animation-duration: 0.2s;
$animation-time: $animation-duration;

$toolbar-height: 48px;
$chat-input-height: 10.4 * $unit;
$content-logo-width: 6 * $unit;
$content-logo-height: 6 * $unit;

// Structural

// General
$color-text: white;

// Input
$input-field-font-size: 1.4rem;
$input-text-bottom-border-color: white;
$input-text-highlight-color: #0086a4;

// Button
$button-primary-color-contrast: white;
$button-primary-color: linear-gradient(262deg, #00465b, #0088a7);
$button-primary-color-hover: linear-gradient(262deg, #00465b, #0088a7);
$button-border-radius: 0;
$expand-button-size: 4 * $unit;

// Login page
$login-logo-width: 34.8 * $unit;
$login-logo-height: 9.6 * $unit;

// Content page
$content-logo-width: 16.6 * $unit;
$content-logo-height: 5.5 * $unit;

$wrapper-width-lg: 104em;
$wrapper-width-sm: 104em;
$wrapper-height: 40em;

$focus-form-width: 50em;
$focus-form-height: 48em;

$focus-reason-form-width: 50em;
$focus-reason-form-height: 40em;

$focus-avform-height: 64.6em;

$focus-blocker_form-height: 36em;
$focus-guest_form-height: 44em;
$footer-height: 166px;

$rightbar-width: 30em;
$rightbar-bg-color: $BG-dark;
$rightbar-color: $BG-medium;
$animation-curve-default: linear;

$tab-menu-height: 6 * $unit;

$header-height: 18em;

// Secondary animation
$numOfElems: 4;
$totalDegrees: 180deg;
$initialDegree: 90deg;
$distance: 3em;

// Layout
$baseline-grid: 8px !default;
$layout-breakpoint-sm: 600px !default;
$layout-breakpoint-md: 940px !default;
$layout-breakpoint-lg: 1200px !default;
$layout-gutter-width: ($baseline-grid * 2) !default;

// General purpose
$hostEndMeetingform-width: 40 * $unit;
$hostEndMeetingform-reason-height: 20 * $unit;
$hostEndMeetingform-button-cancel-background: #e85644;
$hostEndMeetingform-button-send-background: #90e095;
$hostEndMeetingform-button-text-color: #fff;

$guestLogout-width: 40 * $unit;
$guestLogoutform-reason-height: 20 * $unit;
$guestLogoutform-button-cancel-background: #e85644;
$guestLogoutform-button-send-background: #90e095;
$guestLogoutform-button-text-color: #fff;

$kickform-width: 40 * $unit;
$kickform-reason-height: 20 * $unit;
$kickform-button-cancel-background: #e85644;
$kickform-button-send-background: #90e095;
$kickform-button-text-color: #fff;

$hostLogoutform-width: 40 * $unit;
$hostLogoutform-reason-height: 20 * $unit;
$hostLogoutform-button-cancel-background: #e85644;
$hostLogoutform-button-send-background: #90e095;
$hostLogoutform-button-text-color: #fff;

$nobodyInform-width: 40 * $unit;
$nobodyInform-reason-height: 20 * $unit;

$uploadPopupform-width: 40 * $unit;
$uploadPopupform-reason-height: 20 * $unit;
$uploadPopupform-button-cancel-background: #e85644;
$uploadPopupform-button-send-background: #90e095;
$uploadPopupform-button-send-background-disabled: #d0d0d0;
$uploadPopupform-button-text-color: #fff;
$gray-60: #52595e;

$header-height: 18 * $unit;

//Chat container
$chatHeader-height: 40px;
$chatContainer-height: 480px;

//Avatar
$avatarFrameSize: 10rem;

// Material drawer has zIndex of 1300, so elements that need to be above starts from there
// Only for elements that are positioned absolute
$z-index: (
  filedropOverlay: 1302,
);
