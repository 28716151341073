/**
Mixins to reuse in the application
 */

// Adds a "low" default opacity to the component and increases it to 1 when :hover
@mixin hover-opacity($animation-time, $initial-opacity: 0.5) {
  opacity: $initial-opacity;
  -webkit-transition: opacity $animation-time ease-in-out !important;
  -moz-transition: opacity $animation-time ease-in-out !important;
  -ms-transition: opacity $animation-time ease-in-out !important;
  -o-transition: opacity $animation-time ease-in-out !important;
  transition: opacity $animation-time ease-in-out !important;

  &:hover {
    opacity: 1 !important;
  }
}

// It will animate transitions of a property
@mixin animate($property, $animation-time) {
  -webkit-transition: $property $animation-time ease-in-out !important;
  -moz-transition: $property $animation-time ease-in-out !important;
  -o-transition: $property $animation-time ease-in-out !important;
  -ms-transition: $property $animation-time ease-in-out !important;
  transition: $property $animation-time ease-in-out !important;
}

// Rotates an element
@mixin rotate($degrees) {
  -ms-transform: rotate($degrees); /* IE 9 */
  -webkit-transform: rotate($degrees); /* Safari */
  transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -o-transform: rotate($degrees);
}

// Centers an element both vertically and horizontally
@mixin centerer {
  position: absolute !important;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

// Centers an element both horizontally
@mixin h-centerer {
  position: absolute !important;
  left: 50%;

  transform: translate(-50%, 0%);
}

@mixin v-centerer {
  position: absolute !important;
  top: 50%;

  transform: translate(0%, -50%);
}

@mixin v-centerer-footer {
  position: absolute;
  top: calc(50% + 9px);

  transform: translate(0%, -50%);
}

@mixin brandGradient {
  background: #0088a7;
  background: linear-gradient(#0088a7, #00465b);
}

@mixin darkRadialGradient {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: radial-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 2%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.3) 83%,
    rgba(0, 0, 0, 0.6) 100%
  );
}
