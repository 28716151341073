/**
  Creating rules for input elements
 */

input {
  font-weight: 300;

  &::placeholder {
    opacity: 0.3;
  }
}
