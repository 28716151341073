.footer {
  //@include animate(bottom, $animation-time / 2);
  position: absolute;

  z-index: 100;
  bottom: 0;
  left: 0;

  width: 100%;
  height: $footer-height;

  transition: bottom 150ms ease-in;
  transition-timing-function: cubic-bezier(0.22, 0.66, 0.94, 0.9);

  &::before {
    position: absolute;
    z-index: -1;

    display: block;

    width: 100%;
    height: 100%;

    content: "";

    opacity: 0.7;
    background: $c1;
  }

  &.collapsed {
    bottom: -$footer-height + map-get($baseline-sizing, 1);

    &:hover {
      bottom: -$footer-height + map-get($baseline-sizing, 1);
    }
  }

  .expandButtonPlaceHolder {
    // Needed because expandButton scales when "pulsing"
    @include h-centerer;

    top: -2 * $unit;

    overflow: hidden;

    height: $expand-button-size/2;

    .expandButton {
      @extend .circle;
      width: $expand-button-size;
      height: $expand-button-size;

      opacity: 0.7;
      color: white;
      background: $c1;

      .arrowIcon {
        @include animate(
          transform,
          $animation-time / 2
        ); // Animates the icon's rotation
        font-size: 2.4 * $unit;

        width: $expand-button-size;
        height: $expand-button-size;

        cursor: pointer;

        &.down {
          @include rotate(180deg);
        }
      }
    }
  }
}

[class$="slick-disabled"],
[class*="slick-disabled"] {
  display: none !important;
}

[class*="slick-prev"]::before,
[class*="slick-next"]::before {
  font-family: "Material Icons";
  font-size: 30px;
  line-height: 20px;
}

[class*="slick-prev"]::before {
  content: "keyboard_arrow_left";
}

[class*="slick-next"]::before {
  content: "keyboard_arrow_right";
}

[class*="slick-prev"] {
  left: -30px;
}

[class*="slick-next"] {
  right: -20px;
}

.layout-toggle {
  min-width: 43px !important;
  margin: 0 16px !important;

  &:hover {
    background-color: transparent !important;
  }

  &__icon {
    position: absolute !important;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) scale(1.8);
  }
}

.screenShareStream {
  @include v-centerer();
  left: 256px;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) scale(1.2);
  }
}
