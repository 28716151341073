.disconnectForm {
  font-family: $open-sans;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: $focus-reason-form-width;
  margin: auto;
  padding: 5 * $unit;

  text-align: center;

  color: $charcoal-grey;

  background-color: white;
}
