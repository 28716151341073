/**
Animations to reuse in the application
 */

// Glow animation (add .glow class to the element)
.glow {
  -webkit-animation-name: glow-animation;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes glow-animation {
  from {
    color: white;
    -webkit-box-shadow: 0 0 18px $charcoal-grey;
  }

  50% {
    color: white;
    -webkit-box-shadow: 0 0 42px white;
  }

  to {
    color: white;
    -webkit-box-shadow: 0 0 18px $charcoal-grey;
  }
}

// Pulse animation (add .glow class to the element)
.pulse {
  animation-name: pulse-animation;
  animation-duration: 5000ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.3);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.3);
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blinking {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.1;
  }
}

@keyframes slowBlink {
  0% {
    opacity: 0.8;
    filter: grayscale(40%);
  }

  50% {
    opacity: 1;
    filter: grayscale(0);
  }

  100% {
    opacity: 0.8;
    filter: grayscale(40%);
  }
}

@keyframes scaling {
  0% {
    transform: scale(0.5);

    opacity: 0;
  }

  50% {
    transform: scale(1.15);
  }

  70% {
    transform: scale(0.85);
  }

  80% {
    transform: scale(1.1);
  }

  90% {
    transform: scale(1.05);
  }

  95% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);

    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(1000px);
  }
}
