html {
  font-family: $noto-color-emoji $open-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
  overflow-y: hidden;
}

html,
body {
  overflow: hidden;

  height: 100%;
  margin: 0;
}

:focus {
  outline: inherit;
}

// Padding will not affect the element size
.inner-padding {
  box-sizing: border-box;
}

.wordwrap {
  white-space: pre-wrap;
  word-break: break-all;
}

.center {
  margin: 0 auto;

  text-align: center;
}

.bottom-right {
  position: absolute;
  right: map-get($baseline-sizing, 1);
  bottom: map-get($baseline-sizing, 1);
}

.circle {
  border-radius: 50%;
}

.circle-picker {
  display: grid !important;

  width: 100% !important;

  margin: 0 !important;
  grid-template-columns: repeat(6, 1fr);

  @include from($breakpoint-medium) {
    grid-template-columns: repeat(8, 1fr);
  }
}

.noBackground {
  background-color: transparent !important;
}

.noSupport {
  font-size: 11px;

  margin: 16px auto;

  text-align: center;

  color: $salmon;
}

@each $type in 'flex', 'block', 'inline-flex', 'inline-block', 'inline', 'none',
  'grid'
{
  .d-#{$type} {
    display: #{$type} !important;
  }
}

@each $type in 'margin', 'padding' {
  $fl: str-slice($type, 1, 1);

  @each $name, $value in $baseline-sizing {
    .#{$fl}t-#{$name} {
      #{$type}-top: $value !important;
    }

    .#{$fl}b-#{$name} {
      #{$type}-bottom: $value !important;
    }

    .#{$fl}r-#{$name} {
      #{$type}-right: $value !important;
    }

    .#{$fl}l-#{$name} {
      #{$type}-left: $value !important;
    }

    .#{$fl}x-#{$name} {
      @extend .#{$fl}l-#{$name};
      @extend .#{$fl}r-#{$name};
    }

    .#{$fl}y-#{$name} {
      @extend .#{$fl}t-#{$name};
      @extend .#{$fl}b-#{$name};
    }

    .#{$fl}-#{$name} {
      @extend .#{$fl}x-#{$name};
      @extend .#{$fl}y-#{$name};
    }
  }
}

%wordLimit {
  overflow: hidden !important;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-overflow {
  @extend %wordLimit;

  max-width: 100%;
}

.onlyEmoji {
  font-size: 32px;

  margin: 4px 0 0 16px;

  img {
    width: 30px;
    height: 30px;
    margin-bottom: -1px;
  }
}

.me.onlyEmoji {
  margin: 4px 16px 0 0;
}

.fullWidth {
  width: 100%;
}

.scrollbarSpacing {
  padding-right: map-get($baseline-sizing, 2) !important;
}
