.disabled {
  background: gray !important;
}

.event-message {
  font-size: 12px;
  font-weight: 300;
}

.colored-exit {
  font-size: 1.5 * $unit;
}
