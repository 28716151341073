/**
  Rules for chat input
 */

.chatInput {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: $chat-input-height;

  flex-shrink: 0;

  &__toolbar {
    position: relative;
    z-index: 1;

    box-shadow: 0 7px 10px -5px rgba($slate-grey, 0.4);
  }

  &__input {
    display: flex;
    flex: auto;
  }

  .symbols-counter {
    font-size: 10px;

    position: absolute;
    z-index: 4;
    right: map-get($baseline-sizing, 1);
    bottom: map-get($baseline-sizing, 1);

    &.limit {
      opacity: 0.5;
    }

    &.limitReached {
      opacity: 1;
    }
  }

  &__send {
    position: absolute !important;
    z-index: 1;
    top: map-get($baseline-sizing, 3) !important;
    right: map-get($baseline-sizing, 1) !important;

    padding: 4px;

    border-radius: 50%;
  }

  .toolBar {
    position: absolute;
    top: 0;

    width: 100%;
    height: $toolbar-height;
  }

  // TODO: text-area component should start using Material-UI component
  .textArea {
    @extend .inner-padding;

    // Font
    font-family: $open-sans;
    font-size: 12px;
    font-weight: 100;

    position: relative;

    overflow-y: scroll;

    width: 100%;
    margin-top: map-get($baseline-sizing, 6);
    padding: map-get($baseline-sizing, 2) map-get($baseline-sizing, 5)
      map-get($baseline-sizing, 2) map-get($baseline-sizing, 2);

    resize: none;

    // No borders
    border: none;
    outline: none;
    background: none;
    box-shadow: none;

    &:disabled {
      color: rgba($pure-black, 0.87);
      background-color: rgba($pure-black, 0.12);
    }
  }
}
