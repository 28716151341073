// Avatar

.avatar {
  @extend .circle;
  position: relative;

  box-sizing: border-box;

  width: 4 * $unit;
  height: 4 * $unit;

  > .image {
    @extend .circle;
    display: flex;
    overflow: hidden;

    width: 100%;
    height: 100%;

    text-transform: uppercase;

    background-position: center center;
    background-size: cover;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 1.4 * $unit;
      font-weight: 100;

      display: flex;

      width: 100%;
      height: 100%;
      margin: 0;

      color: $white;
      background-color: $charcoal-grey;
      justify-content: center;
      align-items: center;
    }
  }
}

.avatarThumbnail {
  .avatar,
  .initials,
  .avatar > .initials {
    font-size: 30px;
  }
}

$avatarSize: 40px;

.messageAvatar {
  position: absolute;

  top: -($avatarSize/2);
  left: -($avatarSize/2);

  width: $avatarSize;
  height: $avatarSize;

  transform: scale(0.85);
}
