.mediaMenu {
  //This is for grid view big images
  @include h-centerer();

  z-index: 2;
  bottom: 5%;
}

$gridView-member-padding-top: 9px;
$gridView-member-margin-bottom: 19px;

.gridView {
  &__member,
  &__footer__screen {
    width: 220px;
    height: 120px;
  }

  &__slider {
    position: relative;

    margin: 0 48px;

    &__wrapper {
      overflow-x: hidden;

      padding: 9px 0;
    }

    &__container {
      display: flex;

      margin: auto 0;
      padding-top: $gridView-member-padding-top;

      transition: transform 0.2s ease-out;
      flex-wrap: nowrap;
    }
  }

  &__footer {
    &__screen {
      margin: auto 0;
      padding: $gridView-member-padding-top map-get($baseline-sizing, 2)
        $gridView-member-margin-bottom 0;
    }

    .mediaMenu {
      bottom: -20px;

      transform: translateX(-50%) scale(0.8);
    }
  }
}
