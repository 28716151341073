/**
All application button styles
 */

button,
.submit {
  //border: none !important;
  align-self: center;
}

.submit {
  position: relative;

  margin: 16px auto 0;

  text-align: center;
  align-self: center;
}

.closeNoDevice {
  transform: scale(0.55);

  background-color: $hard-negative !important;
}
