.emojiSelector {
  position: absolute;

  z-index: 2;

  display: flex;
  flex-direction: row;

  padding: map-get($baseline-sizing, 1);

  transform: translateY(-100%);

  border: 1px solid $inactive;

  border-radius: 5px;

  background-color: $white;
  justify-content: center;

  &__emoji {
    font-size: 20px;

    display: flex;

    padding: 1px 4px;

    cursor: pointer;

    user-select: none;

    transition: background-color 200ms ease;

    border-radius: 50%;
    justify-content: center;
    align-items: center;

    &:hover,
    &--selected {
      background-color: rgba($darkGray, 0.2);

      span {
        margin-top: 3px;
      }
    }
  }
}
