/**
single chat message used for positioning
 */
.chat-message {
  position: relative;

  &__content {
    font-family: $noto-color-emoji, var(--font-family-ui);
    font-size: 12px;
    font-weight: 100;

    display: block;

    padding: map-get($baseline-sizing, 1);

    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    hyphens: auto;

    color: $charcoal-grey;
    border-radius: var(--border-radius-interactive-small);
    background-color: $white;

    a {
      display: inline-block;

      max-width: 18 * $unit;
    }

    &__insertedLink {
      word-wrap: break-word;

      overflow-wrap: break-word;
    }

    img {
      width: 15px;
      height: 15px;
      margin-bottom: -3px;
    }

    .dynamicLinks a {
      max-width: 100%;

      &:link {
        color: blue;
      }

      &:visited {
        color: purple;
      }
    }

    p {
      margin: 0;
    }
  }

  .chat-timelog {
    text-align: end;
  }
}

.message {
  margin-top: map-get($baseline-sizing, 3);

  + .messageFromUser {
    margin-top: map-get($baseline-sizing, 3);
  }
}

.me {
  .chat-message__content {
    padding: map-get($baseline-sizing, 1) map-get($baseline-sizing, 2)
      map-get($baseline-sizing, 1) map-get($baseline-sizing, 1);

    word-wrap: break-word;
  }
}

.chatContainer {
  .messagesWrapper {
    padding: map-get($baseline-sizing, 3) 0 map-get($baseline-sizing, 2);

    .message {
      width: calc(100% - 4px);
      margin: 4px 8px 12px 4px;
    }
  }
}

%newMessage {
  display: flex;

  width: calc(100% - #{map-get($baseline-sizing, 2)});
  margin: 0 map-get($baseline-sizing, 1);

  .onlyEmoji {
    margin: 0;
  }
}

.messageFromMe .chat-message,
.messageFromMeGrouped .chat-message {
  max-width: calc(100% - 40px);

  margin: 0 map-get($baseline-sizing, 1)
    calc(var(--spacing-half) + var(--spacing-eighth)) auto;

  .onlyEmoji {
    text-align: right;
  }
}

.messageFromMe {
  @extend %newMessage;
  margin-top: map-get($baseline-sizing, 3);

  .chat-message__content {
    padding-right: map-get($baseline-sizing, 3);

    p {
      line-height: 1.4;
    }
  }

  .avatar {
    right: -($avatarSize/2) + 2;
    left: inherit;
  }

  .onlyEmoji {
    margin: 2px map-get($baseline-sizing, 3) 0 0;
  }
}

.messageFromUser .chat-message,
.messageFromUserGrouped .chat-message {
  max-width: calc(100% - 40px);

  margin: 0 auto calc(var(--spacing-half) + var(--spacing-eighth))
    map-get($baseline-sizing, 3);
}

.messageFromUserGrouped,
.messageFromUser {
  + .message,
  + .messageFromMe {
    margin-top: map-get($baseline-sizing, 3);
  }
}

.messageFromMeGrouped,
.messageFromMe {
  + .message,
  + .messageFromUser {
    margin-top: map-get($baseline-sizing, 3);
  }
}

.messageFromUser {
  @extend %newMessage;
  margin-top: map-get($baseline-sizing, 3);

  .chat-message__content {
    padding-left: map-get($baseline-sizing, 2);

    p {
      line-height: 1.4;
    }
  }

  .onlyEmoji {
    margin: 2px 0 0 map-get($baseline-sizing, 3);
  }
}

.messageFromMeGrouped,
.messageFromUserGrouped {
  @extend %newMessage;

  .onlyEmoji {
    margin-top: 1px;
    margin-bottom: -3px;
  }
}

//This is because privatechat doesn't display username, so the spacing is smaller
.chatContainer .messageFromUser {
  margin-top: map-get($baseline-sizing, 3);
}

.system-action-event,
.system-event {
  position: relative;

  display: grid;
  grid-template-columns: map-get($baseline-sizing, 6) 1fr map-get(
      $baseline-sizing,
      4
    );
  align-items: center;
  grid-gap: map-get($baseline-sizing, 1);

  .action-icon,
  .event-icon {
    align-items: flex-start;

    position: relative;

    display: flex;

    width: 100%;
    height: 100%;
    justify-content: center;
  }

  .userName {
    margin: 0;
  }

  .event-message {
    font-weight: 100;

    height: 100%;

    &,
    p {
      margin: 0;
    }
  }
}
