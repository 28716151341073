/**
Icons
 */
.tabIcons {
  width: 100% !important;
  padding: 20px 0 !important;

  color: inherit !important;
}

.smallIcon {
  font-size: 12px !important;
}
