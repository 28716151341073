.wrapper {
  @include v-centerer();
  width: 100%;
}

.remove {
  transform: scale(0.6);
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;

  margin: 0;

  transform: translate(-50%, -50%);
}

.dropFilesOverlay {
  width: 100%;

  &__box {
    position: absolute;

    z-index: map-get($z-index, filedropOverlay);
    right: 0;
    bottom: 0;

    width: 100%;

    height: 100vh;

    background-color: rgba($BG-dark, 0.8);
  }

  &__content {
    position: relative;

    display: flex;
    flex-direction: column;

    height: calc(100vh - 60px);

    margin: 30px;

    border: 1px dashed $white;
    align-items: center;
    justify-content: center;

    p {
      font-size: 15px;
      font-weight: 300;
    }

    &::after {
      position: absolute;
      z-index: 1;
      bottom: 0;

      width: 100%;
      height: 100%;
    }
  }

  &__image {
    height: 60%;
    margin-bottom: map-get($baseline-sizing, 4);
  }
}
