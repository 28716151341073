.chatMessageWrapper {
  position: relative;

  height: 100%;
}

.chatFloater {
  position: relative;

  float: right;

  width: 280px;
  height: 100%;
  margin-left: 10px;

  .chatContainer {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 100%;
    height: calc(100vh - 128px);

    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);

    &--minimized {
      transform: translateY($chatContainer-height - $chatHeader-height);
    }

    &--slideDown {
      animation: slideDown 500ms ease forwards alternate;
    }

    @include from($breakpoint-small) {
      height: $chatContainer-height;
    }

    @include untilLandscape($breakpoint-large) {
      height: calc(100vh - 64px);
    }
  }
  @include from($breakpoint-small) {
    left: auto;
  }
  @include untilLandscape($breakpoint-large) {
    left: map-get($baseline-sizing, 1);
  }
}
